<template>
  <div class="product-page px-6">
    <v-row no-gutters>
      <v-col cols="12" v-if="!dialog">
        <v-card class="mx-auto py-1" outlined>
          <v-card-text class="py-0">
            <v-col cols="12" class="subtitle-1 font-weight-bold py-1">
              Want to save time and lower your fulfillment cost?
            </v-col>
            <v-col cols="12">
              <v-btn outlined color="primary" class="mr-3">
                Enable auto fulfill with Lattehub
              </v-btn>
              <span>We will process and print your orders with mapped products 24 hours after orders are placed</span>
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="page-header d-flex py-3 align-center">
        <span class="page-title">{{ $t('Artwork Library') }}</span>
        <v-spacer></v-spacer>
        <v-col class="d-flex align-center">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="inputArtwork">{{ $t('Upload artworks') }}</v-btn>
          <input
            type="file"
            class="hidden"
            accept="image/gif, image/jpeg, image/png , image/jpg"
            @change="onChangeImage"
            ref="artwork"
            color="red"
            multiple
          />
          <!-- <v-img
            v-if="image && image.src"
            contain
            :src="imageHelpers.url(image.src, { width: 500, height: 500 })"
            :lazy-src="image.src"
            max-with="100%"
            max-height="250"
            min-height="100"
          /> -->
        </v-col>
      </v-col>
      <v-col cols="12" class="d-flex caption">
        Supported files: .PSD, .PNG, .JPG, .JPEG. Drag and drop your files here. Or click Upload artworks to browse
        files from your computer.
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" class="mt-4">
        <!-- <v-card class="mx-auto py-1" outlined>
          <v-card-text class="py-0">
            <v-col cols="12" class="caption font-weight-bold py-2 d-flex">
              By submitting your artworks to Lattehub, you acknowledge that you agree to Lattehub's Terms of Service. We
              will review your campaigns and may enforce certain restrictions on the use of the trademark, including
              canceling orders.
            </v-col>
          </v-card-text>
        </v-card> -->
      </v-col>
      <v-col cols="12">
        <HeaderTableTabs :currentTab.sync="currentTab" :tabs="tabs" />
      </v-col>
      <v-col cols="12">
        <v-text-field class="mt-0 my-0 py-0" :placeholder="`Search by file name`" autocomplete="off" />
      </v-col>
      <v-col cols="12">
        <ListArtwork @selectArtwork="selectArtwork" :dialog="dialog" :loading="isLoading" :totalUpload="totalUpload" />
      </v-col>
      <!-- <v-col cols="12" class="d-flex justify-space-between">
        <limit-page :callback="setLimit" />
        <pagination :visible="0" v-if="isLoading"></pagination>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import imageHelpers from '@/helpers/image';
import ListArtwork from '../components/ListArtwork.vue';
import { printHubApi } from '@/apis/printHub';
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: { ListArtwork },
  data() {
    return {
      isLoading: false,
      tabs: [
        { name: 'All', key: 'all' },
        // { name: 'Personalizable (PSD)', key: 'personalizable' },
        // { name: 'Static', key: 'static' },
      ],
      currentTab: 0,
      image: {},
      imageHelpers,
      totalUpload: 0,
    };
  },
  methods: {
    selectArtwork(item) {
      this.$emit(`selectArtwork`, item);
    },
    inputArtwork() {
      // console.log(this.$refs.artwork);
      this.$refs.artwork.click();
    },
    objectId() {
      return this.hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => this.hex(Math.random() * 16));
    },
    hex(value) {
      return Math.floor(value).toString(16);
    },
    async onChangeImage(e) {
      console.log('length', e.target.files.length);
      this.totalUpload = e.target.files.length;
      this.isLoading = true;
      let size = { width: 0, height: 0 };
      let inputTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
      var _URL = window.URL || window.webkitURL;

      let img = new Image();
      // var objectUrl = _URL.createObjectURL(e.target.files[0]);
      // img.onload = function() {
      //   size.width = this.width;
      //   size.height = this.height;
      //   console.log('size', size.width, size.height);
      // };
      // console.log('size', size);
      // img.src = _URL.createObjectURL(e.target.files[0]);
      if (this.totalUpload > 0) {
        for (let index = 0; index < this.totalUpload; index++) {
          let sizeAllow = false;
          var objectUrl = _URL.createObjectURL(e.target.files[index]);
          img.src = _URL.createObjectURL(e.target.files[index]);
          img.onload = function() {
            size.width = this.width;
            size.height = this.height;
            console.log('size', size.width, size.height);
          };
          console.log('size', size.width, size.height);
          let bool = true;
          if (bool) {
            if (inputTypes.includes(e.target.files[index].type)) {
              let file = e.target.files[index];
              try {
                let type = file.name.split('.')[file.name.split('.').length - 1];
                let id = this.objectId();
                let urlData = await uploadService.get({ filename: `lattehub-image-${id}.${type}` });
                if (urlData) {
                  let res = await axios.put(urlData.data.presignedUrl, file, {
                    headers: {
                      'Content-Type': `${file.type}`,
                    },
                  });
                  await printHubApi.createArtwork({ name: file.name, imageSrc: urlData.data.imagePath });
                  let resArtwork = await printHubApi.getArtwork({ page: 0, limit: 1 });
                  let imageUpload = resArtwork.data.artworks[0];
                  let obj = {
                    name: file.name,
                    alt_text: file.name,
                    imageSrc: urlData.data.imagePath,
                    _id: imageUpload._id,
                    size: size,
                  };
                  this.image = obj;
                  this.$store.commit('updateArtwork', obj);
                }
              } catch (error) {
                this.$store.commit('setMessages', {
                  messages: 'Error image ',
                  type: 'error',
                });
              }
            } else {
              this.$store.commit('setMessages', {
                messages: 'Not allow type ',
                type: 'error',
              });
            }
          } else {
            this.$store.commit('setMessages', {
              messages: 'Sorry, your artwork dimension must be 2000 x 2000px at least. Please try again!',
              type: 'error',
            });
          }
        }
      }
      this.isLoading = false;
      this.totalUpload = 0;
    },
  },
};
</script>

<style lang="scss">
.hidden {
  display: none;
}
</style>
