<template>
  <div @click="dialog ? selectImage() : null">
    <v-card @mouseleave="showbtn = false" outlined class="relative catalog-image pa-2 w-100 h-100">
      <v-img
        @mouseenter="showbtn = true"
        @mouseleave="showbtn = false"
        :lazy-src="imageHelpers.url(item.imageSrc, { width: 500, height: 500 })"
        max-height="300"
        max-width="300"
        :src="imageHelpers.url(item.imageSrc, { width: 500, height: 500 })"
        alt=""
        srcset=""
      ></v-img>
      <!-- <v-img
        class="artwork-image"
        @mouseenter="showbtn = true"
        @mouseleave="showbtn = false"
        alt=""
        src="https://pbimg.xpdcdn.com/pbase/shop-id/10249842/artworks/0x400@feaf5691df04ab2f8f913fc4fdba961d.png"
      ></v-img> -->
      <v-tooltip top class="" v-if="showbtn">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @mouseenter="showbtn = true"
            color="white"
            class="px-1 py-4 artwork-delete"
            x-small
            v-bind="attrs"
            v-on="on"
            @click="deleteArtwork"
          >
            <v-icon color="grey" size="12">
              fas fa-trash
            </v-icon>
          </v-btn>
        </template>
        <span class="" color="white">Delete</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-card-title class="caption pa-0 pt-2 artwork-title" v-bind="attrs" v-on="on">
            <!-- https://pbimg.xpdcdn.com/pbase/shop-id/10249842/artworks/0x400@feaf5691df04ab2f8f913fc4fdba961d.png -->
          </v-card-title>
        </template>
        <!-- <span>https://pbimg.xpdcdn.com/pbase/shop-id/10249842/artworks/0x400@feaf5691df04ab2f8f913fc4fdba961d.png</span> -->
      </v-tooltip>
    </v-card>
  </div>
</template>

<script>
import { printHubApi } from '@/apis/printHub';
import imageHelpers from '@/helpers/image';
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imageHelpers,
      showbtn: false,
    };
  },
  methods: {
    selectImage() {
      this.$emit(`selectArtwork`, this.item);
    },
    async deleteArtwork() {
      console.log('deleteArtwork', this.item._id);
      this.$store.commit('delArtwork', this.item._id);
      try {
        await printHubApi.deleteArtwork(this.item);
      } catch (error) {
        console.log('error', error);
      }
    },
  },
};
</script>

<style lang="scss">
.artwork-delete {
  position: absolute;
  right: 3.5%;
  top: 73%;
  z-index: 2;
}
.artwork-title {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  // font-size: 13px;
}
.artwork-image {
  z-index: 0;
}
</style>
