<template>
  <div>
    <v-row v-if="!isLoading">
      <v-col cols="13" v-for="i in totalUpload" :key="`loading` + i" class="d-flex justify-center align-center">
        <v-card class="col-12 d-flex justify-center align-center process-bar">
          <v-progress-circular
            indeterminate
            color="green"
            class="d-flex align-items-center justify-content-center"
          ></v-progress-circular>
        </v-card>
      </v-col>
      <v-col cols="3" v-for="item in artwork" :key="item.alt">
        <ArtworkItem @selectArtwork="selectArtwork" :dialog="dialog" :item="item" />
      </v-col>
    </v-row>
    <v-row v-if="isLoading">
      <v-col cols="12" class="d-flex justify-center mt-8">
        <!-- <page-loader /> -->
        <v-skeleton-loader width="100%" type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import resourceListMixin from '@/mixins/resource-list';
import ArtworkItem from './ArtworkItem.vue';
export default {
  mixins: [resourceListMixin],
  components: {
    ArtworkItem,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    totalUpload: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(['artwork']),
  },
  methods: {
    selectArtwork(item) {
      this.$emit(`selectArtwork`, item);
    },
  },
  data() {
    return {
      resourceType: 'artwork',
    };
  },
  mounted() {
    console.log('artwork');
  },
};
</script>

<style lang="scss">
.process-bar {
  height: 100%;
  min-width: 226px;
  min-height: 226px;
}
</style>
